<template>
  <div class="index">
    <el-row :gutter="20">
      <el-col :span="8">
        <el-input placeholder="请输入内容" clearable>
          <el-button slot="append" icon="el-icon-search"></el-button>
        </el-input>
      </el-col>
    </el-row>

    <!-- 轮播图 -->
    <el-row class="swiper-wrap">
      <el-col :span="16">
        <el-carousel height="45vh">
          <el-carousel-item v-for="item in swiper_main" :key="item.id">
            <img class="swiper" :src="item.img" alt="" />
          </el-carousel-item>
        </el-carousel>
      </el-col>
      <el-col :span="8">
        <el-row class="my-row">
          <el-col>
            <el-carousel height="22vh">
              <el-carousel-item v-for="item in swiper_sub1" :key="item.id">
                <img class="swiper" :src="item.img" alt="" />
              </el-carousel-item>
            </el-carousel>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-carousel height="22vh">
              <el-carousel-item v-for="item in swiper_sub2" :key="item.id">
                <img class="swiper" :src="item.img" alt="" />
              </el-carousel-item>
            </el-carousel>
          </el-col>
        </el-row>
      </el-col>
    </el-row>

    <!-- 功能区 -->
    <el-row class="func-wrap">
      <el-col :span="8">
        <div class="show1">
          <div class="show-data">
            <div class="numder">{{ all_interview }}</div>
            <div>总访问量</div>
          </div>
          <div class="show-data add_margin">
            <span class="numder">{{ today }}</span>
            <div>今日访问量</div>
          </div>
          <div class="show-but">
            <div class="icon-warp color1">
              <i class="iconfont icon-weixin"></i>
            </div>
            <div class="icon-warp color2">
              <i class="iconfont icon-github"></i>
            </div>
            <div class="icon-warp color3">
              <i class="iconfont icon-QQ"></i>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="4" v-for="app in app_list" :key="app.id">
        <div class="show-app">
          <div class="app-logo">
            <img :src="app.logo" alt="" />
            <span class="logo-number">{{ app.read_num }}</span>
          </div>
          <div class="app-text">
            <div class="title">{{ app.name }}</div>
            <div class="text-data">{{ app.text }}</div>
          </div>
          <div class="app-but" @click="toAppView(app.id)">➜</div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "index",
  data() {
    return {
      swiper_main: [], // 主轮播图
      swiper_sub1: [], // 子轮播图1
      swiper_sub2: [], //  子轮播图2
      app_list: [], // 展示应用
      all_interview: 0, // 总访问量
      today: 0, // 今日访问量
    };
  },
  beforeMount() {
    this.$bus.$emit("islogin");
    if (this.$store.state.index.indexData) {
      const res = this.$store.state.index.indexData
      this.swiper_main = res.swiper_list;
      this.swiper_sub1 = res.sub_swiper1;
      this.swiper_sub2 = res.sub_swiper2;
      this.app_list = res.app_list;
      this.today = res.today;
      this.all_interview = res.all_interview;
    } else {
      this.getIndexData();
    }
  },
  methods: {
    // 获取首页数据
    async getIndexData() {
      const { data: res } = await this.$http.get("home_index");
      if (res.code !== "200") return;
      this.swiper_main = res.swiper_list;
      this.swiper_sub1 = res.sub_swiper1;
      this.swiper_sub2 = res.sub_swiper2;
      this.app_list = res.app_list;
      this.today = res.today;
      this.all_interview = res.all_interview;
      this.$store.commit("index/SAVE_INDEX_DATA", res);
    },

    // 去往项目作品
    toAppView(id) {
      this.$router.push(`/home/product/detail?id=${id}`);
    },
  },
};
</script>

<style lang='less' scoped>
.swiper-wrap {
  margin: 16px 0;
}

.swiper {
  width: 100%;
  height: 100%;
  background: #dadada;
}

.el-carousel__container {
  text-align: center !important;
}

// 功能区
.func-wrap {
  margin-top: 8vh;

  .show1 {
    height: 185px;

    // 数据
    .show-data {
      width: 100%;
      height: 60px;

      div {
        color: rgb(124, 124, 124);
        font-size: 12px;
      }
      .numder {
        font-size: 36px;
        font-weight: 800;
        color: black;
      }
    }
    .add_margin {
      margin-top: 15px;
    }

    // qq微信
    .show-but {
      margin-top: 15px;
      height: 35px;
      width: 100%;
      .icon-warp {
        float: left;
        text-align: center;
        margin-right: 5px;
        line-height: 35px;
        width: 35px;
        border-radius: 10px;
        border: 1px solid rgb(201, 201, 201);
        box-shadow: 1px 2px 1px rgb(201, 201, 201);
        transition: 0.5s;
      }
      .color1 {
        background: #fff;
        color: #2ba245;
      }
      .color1:hover {
        color: #fff;
        background: #2ba245;
      }
      .color2 {
        background: #fff;
        color: #262628;
      }
      .color2:hover {
        color: #fff;
        background: #262628;
      }
      .color3 {
        background: #fff;
        color: #474cfd;
      }
      .color3:hover {
        background: #474cfd;
        color: #fff;
      }
    }
  }
  // 右侧展示功能
  .show-app {
    position: relative;
    margin: 0 10px;
    background: #fff;
    height: 185px;
    width: calc(100% - 20px);
    padding: 5%;
    border-radius: 20px;
    box-shadow: 1px 2px 1px rgb(201, 201, 201);

    // 图标
    .app-logo {
      height: 60px;
      width: 100%;
      padding: 10px;

      img {
        float: left;
        height: 100%;
      }

      .logo-number {
        float: right;
        display: inline-block;
        line-height: 40px;
        font-size: 18px;

        display: -webkit-box;
        // 不换行
        overflow: hidden;
        -webkit-box-orient: vertical;
        // 俩点S
        -webkit-line-clamp: 1;
      }
    }

    // 文字
    .app-text {
      .title {
        font-size: 18px;
        font-weight: 800;
      }

      .text-data {
        font-size: 12px;
        color: rgb(153, 153, 153);
        display: -webkit-box;
        // 不换行
        overflow: hidden;
        -webkit-box-orient: vertical;
        // 俩点S
        -webkit-line-clamp: 3;
      }
    }
    // 按钮
    .app-but {
      position: absolute;
      bottom: 10px;
      right: 10px;
      width: 48px;
      height: 28px;
      background: #3f57ed;
      color: #fff;
      text-align: center;
      line-height: 28px;
      border-radius: 12px;
      cursor: pointer;
    }
  }
}

// ui 样式修改
// 子轮播图1 与 子轮播图2的距离
.my-row {
  margin-bottom: 1vh;
}
</style>